import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import ChangeMakers from "page_components/about/ChangeMakers"
import Label from "components/Label"
import Button from "components/Button"

import StyledAboutUsPage from "styles/AboutUsStyles"

const AboutUsPage = ({ location }) => {
  const data = useStaticQuery(
    graphql`
      query {
        mobile_image: file(relativePath: { eq: "refresh/1600x771_O_NAS.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 600) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        desktop_image: file(
          relativePath: { eq: "refresh/1600x771_O_NAS.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1080) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        acf: wpPage(title: { eq: "O nas" }) {
          title
          acfAbout {
            aboutHeader
            aboutContentLeftDescription
            aboutContentRightDescription
            aboutValuesHeader
            aboutValues {
              aboutValuesIcon {
                localFile {
                  publicURL
                }
              }
              aboutValuesHeader
              aboutValuesDescription
            }
            aboutChangemakers {
              aboutChangemakerImage {
                localFile {
                  publicURL
                }
              }
              aboutChangemakerName
              aboutChangemakerPosition
              aboutChangemakerDescription
            }
          }
        }
      }
    `
  )

  const image_sources = [
    data.mobile_image.childImageSharp.fluid,
    {
      ...data.desktop_image.childImageSharp.fluid,
      media: `(min-width: 600px)`,
    },
  ]

  return (
    <Layout
      location={location}
      seo={{
        title: `${data?.acf?.title} - Poznaj zespół EKO i projekt 4F Change`,
        description:
          "Zmienianie świata zaczynamy od siebie. Zmieniamy swój sposób myślenia i staramy się działać fair, nie tylko na poziomie planety, ale również wewnątrz firmy.",
      }}
    >
      <PageHeader title={data?.acf?.title} />

      <StyledAboutUsPage>
        <div className="main-container">
          <section className="about-us__image">
            {/* <Label text="#think fair_act fair" /> */}
            <Img fluid={image_sources} alt="" />
          </section>

          <section className="about-us__description">
            <header className="description-header">
              <h2
                className="title"
                dangerouslySetInnerHTML={{
                  __html: data?.acf?.acfAbout?.aboutHeader,
                }}
              />
            </header>
            <div className="description-wrapper">
              <div
                className="box"
                dangerouslySetInnerHTML={{
                  __html: data?.acf?.acfAbout?.aboutContentLeftDescription,
                }}
              />
              <div
                className="box"
                dangerouslySetInnerHTML={{
                  __html: data?.acf?.acfAbout?.aboutContentRightDescription,
                }}
              />
            </div>
            <div className="description-link">
              <Button
                text="Więcej"
                href="https://www.otcf.pl/o-nas"
                as="link-out"
              />
            </div>
          </section>

          <section className="about-us__details" id="think_fair">
            <header className="details-header">
              <Label text="#people" />
              <h2
                className="h1 title"
                dangerouslySetInnerHTML={{
                  __html: data?.acf?.acfAbout?.aboutValuesHeader,
                }}
              />
            </header>

            <div className="details-grid">
              {data?.acf?.acfAbout?.aboutValues.map((item, index) => (
                <div className="grid-box">
                  <div className="grid-box__header">
                    <div className="text-holder">
                      <h3
                        className="title"
                        dangerouslySetInnerHTML={{
                          __html: item?.aboutValuesHeader,
                        }}
                      />
                    </div>
                    <div className="icon-holder">
                      <img
                        src={item?.aboutValuesIcon?.localFile?.publicURL}
                        alt={item?.aboutValuesHeader}
                      />
                    </div>
                  </div>
                  <div className="grid-box__text">
                    <p>{item?.aboutValuesDescription}</p>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>

        <ChangeMakers
          changemakersData={data?.acf?.acfAbout?.aboutChangemakers}
        />
      </StyledAboutUsPage>
    </Layout>
  )
}

export default AboutUsPage
